<template>
  <b-card
      v-loading="loading"
      class="statement-editor"
  >
    <el-form
        ref="form"
        label-width="80px"
        :label-position="'right'"
        :model="dataForm"
        :rules="dataRule"
    >
      <b-row>
        <b-col md="3">
          <el-form-item
              label="标题"
              prop="title"
          >
            <el-input
                v-model="queryParams.title"
                :disabled="true"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item label="业务名称">
            <el-input
                v-model="queryParams.productNames"
                :disabled="true"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item prop="versionNo" label="版本">
            <el-input
                v-model="dataForm.versionNo"
                placeholder="请输入版本"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item prop="effectiveTime" label="生效日期">
            <el-date-picker
                v-model="dataForm.effectiveTime"
                placement="bottom-start"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptionsStart"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item
              label="审核人"
              prop="examineUserId"
          >
            <el-select v-model="dataForm.examineUserId">
              <el-option
                  v-for="(item,index) in userList"
                  :key="index"
                  :label="item.actualName"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item
              label="适用地区"
              prop="area"
          >
            <el-input
                v-model="dataForm.area"
                placeholder="请输入区域"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item
              label="语言版本"
              prop="language"
          >
            <el-select v-model="dataForm.language">
              <el-option
                  v-for="(item,index) in languageOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              />
            </el-select>
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="flex-start match-height editor-wrap">
      <div class="doc-left">
        <div class="add-doc">
          <el-button
              class="btn-block text-center"
              size="small"
              type="primary"
              @click="addSection">
            添加新模块
          </el-button>
        </div>
        <VuePerfectScrollbar
            v-loading="tagLoading"
            :style="{height: pageH - 380 +'px'}"
            :settings="settings">
          <draggable
              v-model="dataForm.detailList"
              handle=".mover"
              tag="ul"
              :options="{forceFallback:true, fallbackClass:'draggingStyle'}">
            <li v-for="(item,index) in dataForm.detailList"
                :key="index"
                :class="activeIndex == index ? 'doc-active' : ''"
                class="section-item"
                @click="sectionClick(item,index)">
              <div v-show="!item.editor" class="flex-start mover" style="width: 100%">
                <feather-icon icon="CircleIcon" class="mr10"/>
                <el-tooltip
                    class="item"
                    effect="light"
                    :content="item.directory"
                    placement="top">
                  <span class="overflow-ellipsis directoryName" style="max-width: 70%">
                    {{ item.directory }}
                  </span>
                </el-tooltip>
              </div>
              <div v-show="$hasPermission('statementUpdate')" class="flex-end section-operate">
                <i class="el-icon-edit mr10" @click.stop="editorShow(item, index)"/>
                <i class="el-icon-delete" @click.stop="delSection(index)"/>
              </div>
              <el-input v-show="item.editor" v-model="item.directory" @blur="updateDirectoryName(item,index)">
                <template slot="append">
                  <i class="el-icon-check" @click.stop="updateDirectoryName(item,index)"/>
                </template>
              </el-input>
            </li>
          </draggable>
        </VuePerfectScrollbar>
      </div>
      <div class="doc-right">
        <EditorVue
            :content.sync="detailDTO"
            :page-height="pageH - 380"/>
      </div>
    </div>
    <div class="dialog-footer text-center mt20">
      <el-button
          size="small"
          @click="back"
      >
        返 回
      </el-button>
      <el-button
          type="primary"
          size="small"
          @click="submitForm"
      >
        保 存
      </el-button>
      <el-button
          size="small"
          type="primary"
          @click="preview"
      >
        预 览
      </el-button>
    </div>
  </b-card>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import EditorVue from '@core/components/editor-vue/EditorVue.vue'
import debounce from 'lodash/debounce'
import { queryUserOptions } from '@/api/system/system'

export default {
  components: {
    VuePerfectScrollbar, draggable, EditorVue,
  },
  data() {
    return {
      loading: false,
      tagLoading: false,
      languageOptions: [
        { label: '中文简体', value: 1 },
        { label: '中文繁体', value: 2 },
        { label: '英文', value: 3 },
        { label: '日语', value: 4 },
        { label: '韩语', value: 5 },
        { label: '泰语', value: 6 },
      ],
      countryProps: {
        emitPath: false,
      },
      dataForm: {
        id: 0,
        statementId: 0,
        area: '',
        versionNo: '',
        language: '',
        templateCode: '',
        templateName: '',
        title: '',
        effectiveTime: '',
        examineUserId: undefined,
        detailList: [],
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
      },
      activeIndex: 0,
      editor: null,
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
      backData: {
        pageNo: 1,
        pageSize: 10,
        productNames: '',
        haveFilter: undefined,
      },
      queryParams: {
        filterIds: '',
        templateCode: '',
        pageNo: 1,
        pageSize: 10,
        productNames: '',
        haveFilter: undefined,
        statementId: 0,
        title: '',
        id: 0,
      },
      detailDTO: {
        directory: '',
        content: '2323',
        sortNo: 0,
      },
      userList: [],
      pickerOptionsStart: {
        disabledDate: time => time.getTime() < Date.now() - 8.64e7,
      },
    }
  },
  computed: {
    dataRule() {
      return {
        effectiveTime: [
          { required: true, message: '生效日期不能为空。', trigger: 'blur' },
        ],
        versionNo: [
          { required: true, message: '版本号不能为空。', trigger: 'blur' },
        ],
        examineUserId: [
          { required: true, message: '审核人号不能为空。', trigger: 'blur' },
        ],
      }
    },
  },
  created() {
    this.getParams()
    this.getUserSelect()
    this.init()
  },
  methods: {
    getParams() {
      this.queryParams = this.$route.query
      if (this.queryParams.templateCode === undefined || this.queryParams.statementId === undefined) {
        this.$message.error('参数错误')
      }
      if (this.queryParams.id !== undefined) {
        this.dataForm.id = Number(this.queryParams.id)
      }
      this.dataForm.statementId = Number(this.queryParams.statementId)
    },
    getUserSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.userList = res.data.data
        }
      })
    },
    init() {
      if (this.dataForm.id) {
        this.loading = true
        this.$http.get(`/privacy/statement/version/info?id=${this.dataForm.id}`).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.dataForm = res.data
            if (res.data.detailList.length > 0) {
              this.detailDTO = this.dataForm.detailList[this.activeIndex]
            }
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      } else {
        const templateQuery = {
          templateCode: this.queryParams.templateCode,
          statementId: this.dataForm.statementId,
          filterIdList: this.queryParams.filterIdList,
        }
        this.loading = true
        this.$http.post('/privacy/statement/template/content', templateQuery).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.dataForm = res.data
            if (res.data.detailList.length > 0) {
              this.detailDTO = this.dataForm.detailList[this.activeIndex]
            }
            this.dataForm.examineUserId = undefined
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    },
    preview() {
      // 没保存之前保存在浏览器缓存
      let htmlStr = ''
      this.dataForm.detailList.forEach(d => {
        if (d.content) {
          // eslint-disable-next-line no-const-assign
          htmlStr += d.content
        }
      })
      if (htmlStr) {
        localStorage.setItem('htmlStr', htmlStr)
        const routeUrl = this.$router.resolve({
          path: '/statement-read',
        })
        window.open(routeUrl.href, '_blank')
      } else {
        this.$message.error('内容为空，无法预览')
      }
    },
    back() {
      if (this.queryParams.haveFilter === 'true') {
        this.$router.push({
          path: '/statement-template',
          query: this.queryParams,
        })
      } else {
        this.$router.push({
          path: '/statement-version',
          query: this.queryParams,
        })
      }
    },
    addSection() {
      this.dataForm.detailList.push({
        directory: '',
        content: '',
        sortNo: this.dataForm.detailList.length + 1,
        editor: true,
      })
      this.activeIndex = this.dataForm.detailList.length
      this.detailDTO = this.dataForm.detailList[this.activeIndex]
    },
    delSection(index) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.dataForm.detailList.splice(index, 1)
        this.activeIndex = 0
        this.detailDTO = this.dataForm.detailList[this.activeIndex]
      })
    },
    updateDirectoryName(item, index) {
      const newItem = item
      newItem.editor = false
      this.dataForm.detailList.splice(index, 1, newItem)
    },
    sectionClick(item, index) {
      // this.htmlStr = item.content
      this.activeIndex = index
      this.detailDTO = item
    },
    editorShow(item, index) {
      this.activeIndex = index
      this.detailDTO = item
      const newItem = item
      newItem.editor = true
      this.dataForm.detailList.splice(index, 1, newItem)
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    submitForm: debounce(function () {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$http.post(!this.dataForm.id ? '/privacy/statement/version/create' : '/privacy/statement/version/update', this.dataForm).then(({ data: res }) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500,
                onClose: () => {
                  const pageParams = {
                    pageNo: this.queryParams.pageNo,
                    pageSize: this.queryParams.pageSize,
                    productNames: this.queryParams.productNames,
                    statementId: this.queryParams.statementId,
                    title: this.queryParams.title,
                  }
                  this.$router.push({
                    path: '/statement-version',
                    query: pageParams,
                  })
                },
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
        this.loading = false
      })
    }, 1000, { leading: true, trailing: false }),
  },
}
</script>

<style lang="scss">
.directoryName {
  cursor: move;
}
.doc-dropdown .btn-secondary,
.doc-dropdown .btn-secondary:hover {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.feather-more-vertical {
  color: #6e6b7b !important;
}
.editor-wrap {
  height: 100%;
  border: 1px solid #ebe9f1;
  align-items: baseline;
  border-radius: 4px;
  .doc-left {
    width: 260px;
    border-right: 1px solid #ebe9f1;
    height: 100%;
    .add-doc {
      padding: 10px;
    }
    .section-item {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-w_wendang {
        margin-right: 10px;
      }
      i {
        font-size: 20px;
      }
      .el-input-group__append,
      [dir] .el-input-group__prepend {
        padding: 0 5px !important;
      }
    }
    .section-operate {
      display: none;
      position: absolute;
      right: 10px;
    }
    .section-item:hover {
      .section-operate {
        display: block;
      }
    }
    .section-item.doc-active {
      border-left: 2px solid #0d40b2;
      color: #0d40b2;
      position: relative;
    }
    .tag-title {
      padding: 10px;
      border-top: 1px solid #ebe9f1;
      border-bottom: 1px solid #ebe9f1;
      align-items: center;
      /*color: #b9b9c3;*/
    }
  }
  .doc-right {
    width: calc(100% - 260px);
    height: 100%;
    .doc-search-wrap {
      width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid #ebe9f1;
      height: 40px;
      .doc-search-left {
        width: 80%;
        .el-input__inner {
          border: none !important;
        }
      }
      .el-dropdown {
        display: flex;
        align-items: center;
      }
    }

    .doc-li {
      /*transition: all .2s;*/
      cursor: pointer;
      padding: 20px 10px;
      border-bottom: 1px solid #ebe9f1;
      color: #6e6b7b;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .feather-more-vertical {
        margin-right: 10px;
        position: relative;
        top: 2px;
        opacity: 0;
      }
      .documentName {
        /*margin-left: 10px;*/
        font-size: 12px;
        color: #6e6b7b;
      }
    }
    .doc-li:hover {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 #ebe9f1;
      .feather-more-vertical {
        opacity: 1;
      }
    }
  }
}
.statement-editor .el-date-editor.el-input,
.statement-editor .el-date-editor.el-input__inner{
  width: 100%;
}
</style>
<style>
.w-e-text-container table td,
.w-e-text-container table th {
  border: 1px solid var(--w-e-textarea-border-color);
  padding: 3px 5px;
}
</style>
